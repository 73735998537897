import dataLayer from '@itbpbg/lms-components/src/store/plugins/dataLayer';
import exams from '@itbpbg/lms-components/src/store/plugins/exams';
import banners from '@itbpbg/lms-components/src/store/plugins/banners';
import editorjs from '@itbpbg/lms-components/src/store/plugins/editorjs';
import Cookies from 'js-cookie';
import subscriptions from './plugins/subscriptions';
import auth from './plugins/auth';
import checkout from './plugins/checkout';
import tracking from './plugins/tracking';

export const state = () => ({
  filters: null,
  breakPointLayoutResolution: 1024,
  layout: 'desktop',
  tablet: false,
  laptop: false,
  loader: false,
  copy: null,
  campaign: false,
  downloadAssignment: false,
  curriculumExpanded: true,
});

export const plugins = [
  auth,
  subscriptions,
  exams,
  banners,
  checkout,
  dataLayer,
  tracking,
  editorjs,
];

export const mutations = {
  setFilters(state, payload) {
    state.filters = { ...payload };
  },
  setLayout(state, layout) {
    state.layout = layout;
  },
  setLayoutTablet(state, condition) {
    state.tablet = condition;
  },
  setLayoutLaptop(state, condition) {
    state.laptop = condition;
  },
  loader(state, visible) {
    state.loader = visible;
  },
  setCopy(state, copy) {
    state.copy = copy;
  },
  setCampaign(state, campaign) {
    state.campaign = campaign;
  },
  changeDownloadPermission(state, condition) {
    state.downloadAssignment = condition;
  },
  setCurriculumExpanded(state, condition) {
    state.curriculumExpanded = condition;
    if (process.client) {
      Cookies.set('lmsCurriculumExpanded', condition);
    }
  },
};

export const getters = {
};

export const actions = {
  async nuxtServerInit({ dispatch, commit }, {
    req, res, route, redirect, $axios, $config,
  }) {
    if (route.query.code) {
      let routePath = route.path.replace(/^\/|\/$/g, '');
      routePath = ['checkout', 'resources-center/exams/signup', 'flashcards'].includes(routePath) ? routePath : 'login';
      let queryParams = route.query?.state?.replace(/,/gm, '&');

      if (routePath.includes('checkout')) {
        queryParams += '&checkout=true';
      }

      commit('loader', true);
      const request = await $axios.post(`/auth/social/linkedin?keepMeLoggedIn=true&beta=${!!$config.gateway}&${queryParams}`, {
        token: route.query.code,
        route: routePath,
      }).catch(() => ({ }));

      if (request.data && request.data.businessAccount) {
        dispatch('auth/setUserInfo', request.data);
        return redirect(`/${routePath}${queryParams ? `/?${queryParams}&error=business` : '/?error=business'}`);
      }

      if (request.data && request.data.user) {
        dispatch('auth/setUserInfo', request.data.user);
        res.setHeader('Set-Cookie', request.headers['set-cookie']);
        if (request.data.user.registered) {
          return redirect(`/redirecting/?continue=${routePath}${queryParams ? `/?${queryParams}` : '/'}`);
        }

        return redirect(`/${routePath}${queryParams ? `/?${queryParams}` : '/'}`);
      }
    }

    if (req.headers.cookie) {
      const parsedCookies = req.headers.cookie.split(';').reduce((p, c) => {
        const [k, v] = c.split('=');

        return { ...p, [k.trim()]: decodeURIComponent(v) };
      }, { });

      const {
        user,
        refreshToken,
        accessTokenExpire,
        refreshTokenExpire,
        hideBanner,
        abTests,
      } = parsedCookies;

      commit('banners/toggle', hideBanner);

      if (abTests && abTests !== 'undefined') {
        commit('tests/addUserTests', JSON.parse(abTests));
      }

      if (refreshToken) {
        let userInfo = null;
        try {
          userInfo = JSON.parse(user).user;
        } catch (e) {
          return false;
        }

        if (refreshToken && userInfo) {
          const checkAuth = await dispatch('auth/checkAuth', { accessTokenExpire, refreshTokenExpire });

          if (checkAuth) {
            dispatch('auth/setUserInfo', userInfo);
          }

          if (typeof checkAuth === 'object') {
            const newCookies = checkAuth.reduce((p, c) => (`${p}; ${c.split(';')[0]}`), '');

            req.headers.cookie = req.headers.cookie
              .replace(/accessToken=[^;]+;/g, '')
              .replace(/accessTokenExpire=[^;]+;/g, '')
              .replace(/user=[^;]+;/g, '') + newCookies;
            res.setHeader('Set-Cookie', checkAuth);
          }

          return true;
        }
      }

      return false;
    }

    return false;
  },
  async fetchQuestions(store, { page = 1, limit = 10, search = '' }) {
    const allQuestions = await this.$axios.$get(`/qa-hub/questions?page=${page}&limit=${limit}&search=${search}`);

    if (allQuestions) {
      const { questions, ...rest } = allQuestions;
      const rows = allQuestions.questions.map((e) => ({
        ...e, date: new Date(e.date).toLocaleString(),
      }));
      return { ...rest, rows };
    }

    return {};
  },
};
