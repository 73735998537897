import cookies from 'js-cookie';
import platform from 'platform';
import { debounce } from 'lodash-es';
import { AB_TESTING_NON_CACHEABLE_PAGES } from '../utils/constants';

const EVENTS_PREFIXES = {
  index: 'home',
  'courses-course': 'courseslanding',
  'career-tracks-track': 'career_track_landing',
  'blogCategory-blogSubItem': 'blogpost',
  'blogCategory-blogSubItem-blogArticle': 'blogpost',
  'resources-center-category-slug': 'resourceslanding',
  'calculators-name': 'calculators',
  'question-question': 'qa-question',
};

let TRACKING_SCROLL_SECTIONS = [];

function getElementViewportInfo(el) {
  const rect = el.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

  const vertInView = (rect.top <= windowHeight / 3) && ((rect.top + rect.height) >= 0);

  return { vertInView, rect };
}

const asyncEvents = { };

export default async ({
  $axios, $config, route, store, req,
}, inject) => {
  const tracking = {
    async startSession() {
      if (store.state.tracking.sessionId) {
        store.dispatch('tests/addUserTests', store.state.tests.tests);

        if (store.state.tracking.visitorId) {
          this.setVisitorId(store.state.tracking.visitorId);
        }

        return store.state.tracking.sessionId;
      }

      if (route.query.state) {
        const state = route.query.state.split(',').map((s) => s.split('=')).reduce((p, [k, v]) => ({ ...p, [k]: v }), { });

        if (state.sessionId) {
          store.commit('tracking/setSessionId', state.sessionId);

          if (state.visitorId) {
            this.setVisitorId(state.visitorId);
          }

          if (state.event && state.sourceUrl && state.destinationUrl && route.query.error) {
            const payload = {
              event: `${state.event}.fail`,
              sourceUrl: decodeURIComponent(state.sourceUrl),
              destinationUrl: decodeURIComponent(state.destinationUrl),
              normalized: true,
              error: route.query.error,
            };

            this.sendEvent(payload);
          }

          return false;
        }
      }

      let platformInfo = platform;
      let userAgent = '';

      if (req && req.headers['user-agent']) {
        platformInfo = platform.parse(req.headers['user-agent']);
        userAgent = req.headers['user-agent'];
      }

      if (process.client) {
        userAgent = navigator.userAgent;
      }

      const isBot = userAgent.match(/bot|crawler|spider|crawling/i);

      if (!isBot) {
        const { sessionId, visitorId, tests } = await $axios.$post('/tracking/session', {
          visitorId: this.getVisitorId(),
          utmMedium: route.query.utm_medium,
          utmSource: route.query.utm_source,
          utmContent: route.query.utm_content,
          utmCampaign: route.query.utm_campaign,
          path: route.fullPath,
          browser: `${platformInfo.name} ${platformInfo.version}`,
          device: `${platformInfo.os.toString()}`,
          userAgent,
        }, { noError: true });

        store.dispatch('tests/addUserTests', tests);

        if (process.server) {
          // res.setHeader('Set-Cookie', `abTests=${JSON.stringify(tests)}; path=/; expires=${new Date(new Date().setDate(new Date().getDate() + 1000))}; domain=.${$config.primaryCookiesDomain}`);
        }

        if (process.client) {
          this.setVisitorId(visitorId);
        }

        cookies.remove('session_id', { domain: '365datascience.com' });
        cookies.remove('visitor_id', { domain: '365datascience.com' });
        cookies.remove('abTests', { domain: `.${$config.primaryCookiesDomain}` });

        store.commit('tracking/setSessionId', sessionId);
        store.commit('tracking/setVisitorId', visitorId);

        return sessionId;
      }

      return false;
    },
    setVisitorId(id) {
      if (!this.getVisitorId()) {
        cookies.set('_visitor_id', id, { expires: 1000, domain: `.${$config.primaryCookiesDomain}` });
      }
    },
    getVisitorId() {
      return cookies.get('_visitor_id');
    },
    getFbc() {
      return cookies.get('_fbc') || cookies.get('_365fid'); // this will work only on production beacuse of 365datascience.com domain
    },
    getFbp() {
      return cookies.get('_fbp'); // this will work only on production beacuse of 365datascience.com domain
    },
    getFbuid() {
      return cookies.get('_365fuid'); // this will work only on production beacuse of 365datascience.com domain
    },
    async setGclid(gclid) {
      const timestamp = Date.now();
      const domain = `.${$config.primaryCookiesDomain}`;
      let setCookie = !store.state.auth.user;

      if (!setCookie) { // authenticated user
        // still set flag for cookie if request is not successful
        setCookie = (await $axios.$post('/tracking/google-oci', { gclid, timestamp }).catch(() => false)) === false;
        if (!setCookie) { // if request is successful
          cookies.remove('_365id', { domain });
        }
      }

      if (setCookie) { // set cookie
        cookies.set('_365id', `${gclid}.${timestamp}`, { expires: 90, domain });
      }
    },
    async setFBclid(fbclid) {
      const timestamp = Date.now();
      const domain = `.${$config.primaryCookiesDomain}`;
      cookies.set('_365fid', `fb.1.${timestamp}.${fbclid}`, { expires: 90, domain });
    },
    async setFBuid(uid) {
      const domain = `.${$config.primaryCookiesDomain}`;
      cookies.set('_365fuid', `${uid}`, { expires: 90, domain });
    },
    getAsyncEvents() {
      return asyncEvents;
    },
    async sendAsyncEvent(event, payload) {
      const e = asyncEvents[event];

      if (e) {
        await e.send(payload);
      }

      return false;
    },
    setTrackingSections() {
      TRACKING_SCROLL_SECTIONS = document.querySelectorAll('.tracking-section');
    },
    resetTrackingSections() {
      TRACKING_SCROLL_SECTIONS = [];
    },
    setEventListeners() {
      window.addEventListener('DOMContentLoaded', () => {
        this.setTrackingSections();
      });

      window.addEventListener('click', async (e) => {
        let { target } = e;
        let { event } = target.dataset;

        if (!event && e.target.closest('[data-event$=".click"]')) {
          ({ event } = e.target.closest('[data-event$=".click"]').dataset);
          target = e.target.closest('[data-event$=".click"]');
        }

        if (event) {
          store.commit('tracking/setEvent', event);

          if (target.getAttribute('target') || target.hasAttribute('data-direct-send')) {
            if (target.hasAttribute('data-async-event')) {
              const payload = {
                event,
                sourceUrl: window.$nuxt.$route.fullPath,
                destinationUrl: window.$nuxt.$route.fullPath,
                route: window.$nuxt.$route,
              };

              asyncEvents[event] = {
                send: async ({ status, error }) => this.sendEvent({
                  ...payload,
                  error,
                  event: status ? `${event}.${status}` : event,
                }),
                payload: this.normalizeEventPayload(payload),
              };
            } else {
              await this.sendEvent({
                event,
                sourceUrl: window.$nuxt.$route.fullPath,
                destinationUrl: window.$nuxt.$route.fullPath,
                route: window.$nuxt.$route,
              });
            }

            store.commit('tracking/setEvent', null);
          }
        } else {
          store.commit('tracking/setEvent', null);
        }
      }, true);

      window.addEventListener('scroll', debounce(() => this.sectionIsVisible((e) => {
        if (store.state.tracking.event !== e) {
          store.commit('tracking/setEvent', e);
          this.sendEvent({
            event: e,
            sourceUrl: window.$nuxt.$route.fullPath,
            destinationUrl: window.$nuxt.$route.fullPath,
            route: window.$nuxt.$route,
          });
        }
      }), 1000));

      window.popStateDetected = false;
      window.addEventListener('popstate', () => {
        window.popStateDetected = true;
      });
    },
    sectionIsVisible(cb) {
      const visibleSections = [];
      TRACKING_SCROLL_SECTIONS.forEach((s) => {
        const viewportInfo = getElementViewportInfo(s);
        if (viewportInfo.vertInView) {
          const { event } = s.dataset;

          if (event) {
            visibleSections.push({ top: viewportInfo.rect.top, event });
          }
        }
      });

      const [section] = visibleSections.sort((a, b) => b.top - a.top) || [];

      if (section && section.event) {
        cb(section.event);
      }
    },
    normalizeEventPayload(e) {
      if (e.normalized) {
        return e;
      }

      let prefix = '';
      if (e.route) {
        prefix = EVENTS_PREFIXES[e.route.name] || e.route.name.replace('-', '_');
      }

      const event = {
        event: prefix && e.event ? `${prefix}_${e.event}` : '',
        dataLayerEvent: e.dataLayerEvent || 'PageView',
        destinationUrl: e.destinationUrl,
        sourceUrl: e.sourceUrl,
        error: e.error,
        fbp: this.getFbp(),
        fbc: this.getFbc(),
        fbLoginId: this.getFbuid(),
        value: e.value,
        currency: e.currency,
        eventID: e.eventID,
        userAgent: e.userAgent,
        normalized: true,
      };

      return event;
    },
    async sendEvent(e) {
      const event = this.normalizeEventPayload(e);

      const visitorId = this.getVisitorId();

      if (!+visitorId) {
        return false;
      }

      const { sessionId } = await $axios.$post('/tracking/session/events', {
        events: [event],
        sessionId: store.state.tracking.sessionId,
        utmMedium: route.query.utm_medium,
        utmSource: route.query.utm_source,
        utmContent: route.query.utm_content,
        utmCampaign: route.query.utm_campaign,
        browser: `${platform.name} ${platform.version}`,
        device: `${platform.os.toString()}`,
        capi: e.capi,
        session: e.session,
      }, { progress: false, noError: true });

      if (store.state.tracking.sessionId !== sessionId) {
        store.commit('tracking/setSessionId', sessionId);
      }

      return sessionId;
    },
  };

  inject('tracking', tracking);

  if ((AB_TESTING_NON_CACHEABLE_PAGES.includes(route.path) || AB_TESTING_NON_CACHEABLE_PAGES.includes(route.name)) && process.server) {
    await tracking.startSession();
  } else if (process.client) {
    tracking.setEventListeners();
    const sessionId = await tracking.startSession();

    if (route.query.gclid) {
      tracking.setGclid(route.query.gclid);
    }

    if (route.query.fbclid) {
      tracking.setFBclid(route.query.fbclid);
    }

    dataLayer.push({
      event: 'Pageview',
      pagePath: window.location.pathname + window.location.search,
      pageTitle: document.title,
      eventID: sessionId,
    });
  }
};
